const BlogContent = [
  {
    images: '1',
    title: 'AI Hack 2022',
    Place: 'Tunisia',
    Time: "September 2022",
    url: 'https://www.facebook.com/AIHackTunisia/videos/650454373092456/',
  },
  {
    images: '2',
    title: 'Hématologie Workshop 2023',
    Place: 'Tunisia',
    Time: "March 2023",
    url: 'https://www.linkedin.com/posts/amine-m-b435a83a_precision-hematology-workshop-from-the-2ed-activity-7030095272416276480-jMfB/?utm_source=share&utm_medium=member_ios',
  }
 
];

export default BlogContent;